import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TableListEmployeeNotes from '../../markdowns/sections/docs/employeenotes/response-list-employee-notes.md';
import TableRetrieveEmployeeNoteDetails from '../../markdowns/sections/docs/employeenotes/response-retrieve-employee-note-details.md';
export const _frontmatter = {
  "title": "Employee Notes"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Employee Notes`}</h1>
    <h2>{`List all employee Notes`}</h2>
    <p>{`Use this action to retrieve list of active notes of an Employee. All types of notes are listed through this end point.`}</p>
    <div className="m-5 text-center">
    <img src="/images/docs/employeenotes/list-employee-notes.png" alt="Employee Notes List" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveEmployeeNotes/"
              }}>{`GET: List Employee Notes`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br /></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableListEmployeeNotes mdxType="TableListEmployeeNotes" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employeenotes/list-employee-notes-ehr-ui.png" alt="enableHR Employee Notes List" style={{
        "maxWidth": "60%"
      }} />
    </div>
    <h2>{`Retrieve an employee Note details`}</h2>
    <p>{`Use this action to retrieve more details about an employee note. Various types of employee note details can be retrieved using this end point like comment, email, Record Note, ESS task etc.`}</p>
    <div className="m-5 text-center">
    <img src="/images/docs/employeenotes/retrieve-employee-note-details.png" alt="Retrieve Employee Note Details" style={{
        "maxWidth": "80%"
      }} />
    </div>
    <div className="markdown-section">
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Method`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/development/RetrieveEmployeeNote/"
              }}>{`GET: Retrieve Employee Note Details`}</a></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Parameters`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><a parentName="td" {...{
                "href": "/docs/accounts-and-branches/#account-id"
              }}>{`Account ID`}</a>{` - Unique identifier of the Account`}<br /><a parentName="td" {...{
                "href": "/docs/employees/#list-all-employees"
              }}>{`Employee ID`}</a>{` - Unique identifier of the Employee`}<br />{`Note ID - Unique identifier of an Employee Note`}<br /></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Response`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}><TableRetrieveEmployeeNoteDetails mdxType="TableRetrieveEmployeeNoteDetails" /></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="m-5 text-center">
    <img src="/images/docs/employeenotes/retrieve-employee-note-details-ehr-ui.png" alt="enableHR Retrieve Employee Note Details" style={{
        "maxWidth": "60%"
      }} />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      